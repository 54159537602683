var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-matching-question"},[_c('div',{staticClass:"table-left"},[_c('table',{staticClass:"term-table"},[_c('thead',[_c('tr',[(_vm.allows_crud)?_c('th'):_vm._e(),_c('th',{staticClass:"input-number1"},[_vm._v("N°")]),_c('th',{staticClass:"input-term1"},[_vm._v("Término 1")]),(_vm.allows_crud)?_c('th',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                `${
                  _vm.allows_crud
                    ? 'Selecciona el número de opciones correctas'
                    : ''
                }`
              ),expression:"\n                `${\n                  allows_crud\n                    ? 'Selecciona el número de opciones correctas'\n                    : ''\n                }`\n              ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticStyle:{"width":"19%"}},[_vm._v(" N° de Opciones ")]):_vm._e(),(
                _vm.allows_crud ||
                (_vm.preview_evaluation && !_vm.evaluatee_view && !_vm.evaluator_view) ||
                (_vm.evaluatee_view && !_vm.finished_test && !_vm.evaluator_view)
              )?_c('th',{staticClass:"noprint"}):_vm._e()])]),_c('MatchingQuestionDraggableOption',{attrs:{"display_position_left":true,"allows_crud":_vm.allows_crud,"container_instrument_id":_vm.container_instrument_id,"ids_left_position":_vm.ids_left_position,"tem_ids_left_position":_vm.tem_ids_left_position,"Question":_vm.Question,"preview_evaluation":_vm.preview_evaluation,"evaluatee_view":_vm.evaluatee_view,"evaluator_view":_vm.evaluator_view,"finished_test":_vm.finished_test,"user_id":_vm.user_id}})],1),(_vm.allows_crud)?_c('b-button',{staticClass:"mt-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.createdOption(true)}}},[_vm._v(" + Agregar alternativa ")]):_vm._e()],1),_c('div',{staticClass:"table-right"},[_c('table',{staticClass:"term-table"},[_c('thead',[_c('tr',[(_vm.allows_crud)?_c('th'):_vm._e(),(_vm.user_id && _vm.evaluator_view)?_c('th',{staticClass:"input-number2"},[_vm._v(" Respuesta ")]):_vm._e(),_c('th',{staticClass:"input-number2"},[_vm._v("N°")]),_c('th',{staticClass:"input-term2"},[_vm._v("Término 2")]),(
                _vm.allows_crud ||
                (_vm.preview_evaluation && !_vm.evaluatee_view && !_vm.evaluator_view) ||
                (_vm.evaluatee_view && !_vm.finished_test && !_vm.evaluator_view)
              )?_c('th',{staticClass:"th-right-btn noprint"}):_vm._e()])]),_c('MatchingQuestionDraggableOption',{attrs:{"display_position_left":false,"allows_crud":_vm.allows_crud,"container_instrument_id":_vm.container_instrument_id,"ids_left_position":_vm.ids_left_position,"tem_ids_left_position":_vm.tem_ids_left_position,"Question":_vm.Question,"preview_evaluation":_vm.preview_evaluation,"evaluatee_view":_vm.evaluatee_view,"finished_test":_vm.finished_test,"evaluator_view":_vm.evaluator_view,"user_id":_vm.user_id},on:{"clear_ids_left_position":_vm.clearIdsLeftPosition,"clear_tem_ids_left_position":_vm.clearTemIdsLeftPosition}})],1),(_vm.allows_crud)?_c('b-button',{staticClass:"mt-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.createdOption(false)}}},[_vm._v(" + Agregar alternativa ")]):_vm._e()],1)]),_c('div',{staticClass:"container-score"},[_c('MatchingQuestionScore',{attrs:{"Question":_vm.Question,"allows_crud":_vm.allows_crud,"evaluator_view":_vm.evaluator_view,"preview_evaluation":_vm.preview_evaluation}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }